<template>
  <base-section
    id="waterquality"
    space="36"
  >
    <v-spacer />
    <div v-if="$route.name=='수질용'">
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>
      <base-section-heading title="수질 방지제" />
      <v-container
      class="text-center"
      >
      공장, 빌딩, 병원, 호텔 등의 냉각수 관리에 있어 가장 중요한 요소는 부식방지 및 미생물에 의한 슬라임 억제입니다.<br>
      <br>
      식품생산 공장의 냉각수 장치에 부식 및 미생물에 의한 슬라임이 발생할 경우,<br>
      냉각수의 악취 뿐만 아니라, 시설의 노후화 및 생산효율의 감소 등이 발생 할 수 있습니다.<br>
      <br>
      또한 빌딩, 병원, 호텔 등의 냉각수 관리가 미흡할 경우 레지오넬라균, 사상균, 잡균 등의 오염으로 인하여, 감염의 우려가 높아지게 됩니다.<br>
      특히 기온의 영향으로 여름철 냉각수 관리의 필요성은 증가되게 됩니다.<br>
      이경우 냉각수 부식방지 및 수질 부패 방지제를 통하여 관리 하실 수 있습니다.<br>
      </v-container>
      <v-container>
      </v-container>

      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                냉각수에 의한 철 부식 및 녹 방지제(식품산업용)
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                수질 부패 방지 및 이끼제거제(식품산업용)
              </v-subheader>
            </v-card>
        </v-col>
  </base-section>
</template>

<script>
  export default {
    name: 'Sectionwaterquality',
    data: () => ({
      inset: false,
      drawer: null,
      items: [
        'Cip',
        'Cop',
        '세병첨가제',
        '컨베이어 윤활제',
        '생수공장용',
        '수질용',
      ],
    }),

    provide: {
      theme: { isDark: false },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
</style>
